import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { isLoggedIn } from "utils";

export const getEvaluationCourses = async (data) => {
  return decryptData(
    await axios.get(
      `/evaluation/course?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getEvaluationTestByCourses = async (data) => {
  return decryptData(
    await axios.get(
      `/evaluation/test/evaluator?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getEvaluationStudentByTest = async (data) => {
  return decryptData(
    await axios.get(
      `/evaluation/test/student?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const assignTestToEvaluator = async (data) => {
  return decryptData(
    await axios.get(
      `/evaluation/test/assign?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const evaluatorListForDashboard = async (data) => {
  return decryptData(
    await axios.get(
      `/evaluation/evaluator/list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const evaluatorBilling = async (data) => {
  return decryptData(
    await axios.get(
      `/evaluation/evaluator/billing?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const evaluatorBillingExcelDownload = async (data) => {
  return `${
    process.env.REACT_APP_API_URL
  }/admin/evaluation/evaluator/billing?reqData=${encryptData({
    ...data,
    access_token: isLoggedIn(),
  })}`;
};

export const uploadStudentAnswerSheet = async (data, option) => {
  return decryptData(await axios.post(`/evaluation/upload/pdf`, data, option));
};

// get moderated test list
export const getModeratedCopies = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/evaluation/moderated/best/copy/list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// mark best copies
export const markBestCopy = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/evaluation/mark/best/copy?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// get best copies
export const getBestCopies = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/evaluation/best/copy/list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// download evaluation summery
export const downloadEvaluationSummery = async (data = {}) => {
  return `${
    process.env.REACT_APP_API_URL
  }/admin/evaluation/summary/downloadExcel?reqData=${encryptData({
    ...data,
    access_token: isLoggedIn(),
  })}`;
};
