import ModalBox from "components/modal/ModalBox";
import NotFound from "components/not-found/NotFound";
import useFullPageLoader from "hooks/useFullPageLoader";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Pagination from "react-js-pagination";
import { dateFormat } from "utils";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "components/Button/Button";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import { useSearchParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import IconButton from "components/Button/IconButton";
import { deleteAdvertisement, getAdvertisements } from "services/advertisement";
import { getSiteSetting, updateSiteSettingStatus } from "services/sitesetting/sitesettingcontroller";
import Switch from "components/Switch/Switch";
import UpdateSiteSetting from "./UpdateSiteSetting";

// import AddPopup from "./AddPopup";
import { Avatar } from "@mui/material";

const ViewSiteSetting = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  const activePage = +searchParam.get("page") || 1;

  const [record, setRecord] = useState([]);
  const [total, setTotal] = useState(0);

  const [selectedImage, setSelectedImage] = useState("");
  const [imageModal, setImageModal] = useState("");

  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [selectedData, setSelectedData] = useState({});

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();

  const [selectedId, setSelectedId] = useState("");
  const [blockModal, setBlockModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);



  //**********popup Listing**********/
  const SiteEsttingListing = async (page = activePage) => {
    try {
      showLoader();
      const res = await getSiteSetting({ page });
      hideLoader();
      if (res.code === 200) {
        const { config, count } = res.data;
        if (config.length <= 0) {
          searchParam.set("page", 1);
          setSearchParam(searchParam);
        }
        setRecord(config || []);
        setTotal(count || 0);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };





  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    SiteEsttingListing(activePage);
    // eslint-disable-next-line
  }, [searchParam]);


  // Update status
  const updateStatusHandler = async () => {
    if (selectedId) {
      try {
        setSubmitLoading(true);
        const res = await updateSiteSettingStatus({
          id: selectedId,
        });
        if (res.code === 200) {
          SiteEsttingListing();
          toast.success(res.message);
          setBlockModal(false);
          setSubmitLoading(false);
        } else {
          toast.error(res.message);
          setSubmitLoading(false);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
        setSubmitLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <title>Shubhra Viraj | Site Setting</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox">
                <div className="div_wrap">

                </div>

              </div>
              <h4 className="Title">Site Setting</h4>
            </div>
            <div className="TableList overflow-auto table-responsive">
              {loader}
              <Table bordered className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Type</th>
                    <th>Setting Value</th>
                    <th>Created Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {record && record.length > 0 ? (
                    record.map(({ id, type, value, createdAt, status }, i) => (
                      <tr key={i}>
                        <td>{++i + (activePage - 1) * 10}</td>
                        <td>
                          {type === 1 && "Daily Quiz Date Limit"}
                          {type === 2 && "Video Cipher Key"}
                          {type === 3 && "MockTest URL"}
                          {type === 4 && "Workshop URL"}
                        </td>
                        <td>
                          {/* Display only the last four digits of the video cipher key */}
                          {type === 2 ? `XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX${value.slice(-4)}` : value}
                        </td>
                        <td>{dateFormat(createdAt)}</td>
                        <td>
                          <Switch
                            checked={status}
                            onChange={() => {
                              setSelectedId(id);
                              setBlockModal(true);
                            }}
                            type="acl"
                            role={161002}
                          />
                        </td>
                        <td>
                          <div className="d-flex justify-content-center w-100">
                            <IconButton
                              aria-label="view"
                              className="px-2 py-0"
                              onClick={() => {
                                setSelectedData(prevData => ({
                                  ...prevData,
                                  id,
                                  value
                                  // value: id === 2 ? `XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX${value.slice(-4)}` : value,
                                }));
                                setUpdateModal(true);
                              }}
                              type="acl"
                              role={161001}
                            >
                              <EditIcon sx={{ color: "#4971B2" }} />
                            </IconButton>

                          </div>
                        </td>
                      </tr>
                    ))

                  ) : (
                    <NotFound cols={12} />
                  )}
                </tbody>
              </Table>
            </div>
            {total > 10 ? (
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>



      {/* Status Modal */}

      <ModalBox
        status={blockModal}
        onClose={() => setBlockModal(false)}
        title={"Change Status"}
        description={"You want to change status of this setting value ?"}
        customizedFooter={
          <Button onClick={updateStatusHandler} {...submitButtonElement}>
            Yes
          </Button>
        }
      />

      {/* Update Modal */}
      <UpdateSiteSetting
        open={updateModal}
        onClose={() => setUpdateModal(false)}
        SiteEsttingListing={SiteEsttingListing}
        selectedData={selectedData}
      />



    </React.Fragment>
  );
};

export default ViewSiteSetting;
