import React, { memo, useEffect, useState } from "react";

import { toast } from "react-hot-toast";
import { Input } from "components/Form";
import { Button } from "components/Button/Button";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import ModalBox from "components/modal/ModalBox";
import { updateSiteSettingValue } from "services/sitesetting/sitesettingcontroller";

const UpdateSiteSetting = ({
    open,
    onClose,
    SiteEsttingListing,
    selectedData,
  }) => {
    const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
  
    const [siteData, setsiteData] = useState({
      value: "",
    });
  
    const [errorField, setErrorField] = useState({
        value: "",
    });
  
    const [allCategories, setAllCategories] = useState([]);
  
    //*********************Handel Validation********************************** */
    const handleValidation = () => {
      let errorField = {};
      let formIsValid = true;
  
      if (!siteData.value) {
        errorField["value"] = "Please Enter Value";
        formIsValid = false;
      }
      
      setErrorField(errorField);
      return formIsValid;
    };
  
    // this function is used for clear data on close
    const modalOnClose = () => {
        setsiteData({
        value: "",
      });
      onClose();
    };
  
    //*********************Submit Category Handler********************************** */
    const submitCategoryHandler = async (e) => {
      e.preventDefault();
      if (handleValidation()) {
        setSubmitLoading(true);
        try {
          const res = await updateSiteSettingValue(siteData);
          if (res.code === 200) {
            toast.success(res.message);
            setSubmitLoading(false);
            modalOnClose();
            SiteEsttingListing();
          } else {
            toast.error(res.message);
            setSubmitLoading(false);
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || err.message);
          setSubmitLoading(false);
        }
      } else {
        toast.error("Please fill all the fields");
      }
    };
  
    //   onChange Handler
    const onChangeHandler = (e) => {
      const { name, value } = e.target;
      setsiteData({ ...siteData, [name]: value });
    };
  
    useEffect(() => {
      if (open) {
          if (selectedData.type === 4) {
              // If type is 4, set masked value
              setsiteData({
                  id: selectedData.id,
                  value: `XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX${selectedData.value.slice(-1)}`,
              });
          } else {
              // Otherwise, set original value
              setsiteData({
                  id: selectedData.id,
                  value: selectedData.value,
              });
          }
      }
  }, [open, selectedData]);
  
  
     
     
  
    return (
      <>
        <ModalBox
          status={open}
          onClose={modalOnClose}
          title={"Update Setting Value"}
          customizedFooter={
            <div>
              <Button onClick={submitCategoryHandler} {...submitButtonElement}>
                Update Setting Value
              </Button>
            </div>
          }
        >
          <div className="form-group row">
            
            <div className="col-sm-12 col-form-label">
              <Input
                type="text"
                name="value"
                value={siteData.value}
                onChange={onChangeHandler}
                placeholder="Enter Value"
                className={`form-control `}
                maxLength={100}
                label={"Setting Value"}
                error={errorField.value}
                required
              />
            </div>
            
          </div>
        </ModalBox>
      </>
    );
  };

export default memo(UpdateSiteSetting);
