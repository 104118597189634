import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { isLoggedIn } from "utils";

export const getCourses = async (data) => {
  return decryptData(
    await axios.get(
      `courses?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getCources = async (data) => {
  return decryptData(
    await axios.get(
      `/active-courses?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const addCourse = async (data) => {
  return decryptData(
    await axios.post(`courses-create`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

export const updateCourse = async (data) => {
  return decryptData(
    await axios.post(`courses-edit`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

export const updateCourseStatus = async (data) => {
  return decryptData(
    await axios.get(
      `courses-status?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const deleteCourse = async (data) => {
  return decryptData(
    await axios.get(
      `courses-delete?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getSingleCourseById = async (data) => {
  return decryptData(
    await axios.get(
      `/courses-view?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
