import React, { lazy } from "react";

// MUI Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import Groups3Icon from "@mui/icons-material/Groups3";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import SubjectIcon from "@mui/icons-material/Subject";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import LanguageIcon from "@mui/icons-material/Language";
import DiscountIcon from "@mui/icons-material/Discount";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HelpIcon from "@mui/icons-material/Help";
import HubIcon from "@mui/icons-material/Hub";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import QuizIcon from "@mui/icons-material/Quiz";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ViewSiteSetting from "pages/WebsiteManagement/SiteSetting/ViewSiteSetting";
import LiveLectureDetailsHistory from "pages/Reports/LectureDetails/LiveLectureDetailsHistory";
//import CourseMapping from "pages/CourseManagement/CourseMapping/CourseMapping";
//import LectureDetailsHistory from "pages/Reports/LectureDetailsHistory/LectureDetailsHistory";

const Dashboard = lazy(() => import("pages/Dashboard/Dashboard"));
const ChangePassword = lazy(() =>
  import("pages/ChangePassword/ChangePassword")
);

// ----------------------- Course Management -----------------------//
const UpdateCourseCategory = lazy(() =>
  import("pages/CourseManagement/CourseCategory/UpdateCourseCategory")
);
const SingleCourseCategory = lazy(() =>
  import("pages/CourseManagement/CourseCategory/SingleCourseCategory")
);
const AddCourse = lazy(() =>
  import("pages/CourseManagement/Courses/AddCourse")
);
const SingleBillingCourse = lazy(() =>
  import("pages/CourseManagement/BillingCourse/SingleBillingCourse")
);
const UpdateCourse = lazy(() =>
  import("pages/CourseManagement/Courses/UpdateCourse")
);
const UpdateBillingCourse = lazy(() =>
  import("pages/CourseManagement/BillingCourse/UpdateBillingCourse")
);
const DetailVideoMapping = lazy(() =>
  import("pages/CourseManagement/BillingCourse/Mapping/DetailVideoMapping")
);
const RecommendedCoursesMapping = lazy(() =>
  import(
    "pages/CourseManagement/BillingCourse/Mapping/RecommendedCoursesMapping"
  )
);
const DemoLectureMapping = lazy(() =>
  import("pages/CourseManagement/BillingCourse/Mapping/DemoLectureMapping")
);
const FaqMapping = lazy(() =>
  import("pages/CourseManagement/BillingCourse/Mapping/FaqMapping")
);
const CoreTakeAwayMapping = lazy(() =>
  import("pages/CourseManagement/BillingCourse/Mapping/CoreTakeAwayMapping")
);
const CoursePlatformMapping = lazy(() =>
  import("pages/CourseManagement/BillingCourse/Mapping/CoursePlatformMapping")
);
const ViewMappedSubject = lazy(() =>
  import("pages/CourseManagement/SubjectMapping/ViewMappedSubject")
);

const CourseMapping = lazy(() =>
  import("pages/CourseManagement/CourseMapping/CourseMapping")
);

const ViewBillingCourses = lazy(() =>
  import("pages/CourseManagement/BillingCourse/ViewBillingCourses")
);
const IOSBillingCourse = lazy(() =>
  import("pages/CourseManagement/BillingCourse/IOSBillingCourse")
);
const AddBillingCourse = lazy(() =>
  import("pages/CourseManagement/BillingCourse/AddBillingCourse")
);
const ViewCourseCategory = lazy(() =>
  import("pages/CourseManagement/CourseCategory/ViewCourseCategory")
);
const AddCourseCategory = lazy(() =>
  import("pages/CourseManagement/CourseCategory/AddCourseCategory")
);
const ViewSubCategories = lazy(() =>
  import("pages/CourseManagement/CourseSubCategory/ViewSubCategories")
);

const ViewSubCategoryPlatform = lazy(() =>
  import("pages/CourseManagement/SubCategoryPlatformManagement/ViewSubCategoryPlatform")
);


const ViewGPSCourses = lazy(() =>
  import("pages/CourseManagement/GPSEnabledCourses/ViewGPSCourses")
);
const AddGPSCourse = lazy(() =>
  import("pages/CourseManagement/GPSEnabledCourses/AddGPSCourse")
);
const ViewGPSAccessStudent = lazy(() =>
  import("pages/CourseManagement/GPSAccessStudent/ViewGPSAccessStudent")
);
const UpdateGPSCourse = lazy(() =>
  import("pages/CourseManagement/GPSEnabledCourses/UpdateGPSCourse")
);
const SingleGPSCourse = lazy(() =>
  import("pages/CourseManagement/GPSEnabledCourses/SingleGPSCourse")
);

const ViewCourses = lazy(() =>
  import("pages/CourseManagement/Courses/ViewCourses")
);
const CourseSectionList = lazy(() =>
  import("pages/CourseManagement/Courses/CourseSection/CourseSectionList")
);
const CourseLectureList = lazy(() =>
  import("pages/CourseManagement/Courses/CourseLectures/CourseLectureList")
);
const SectionLectureList = lazy(() =>
  import("pages/CourseManagement/Courses/CourseSection/MapSectionLecture")
);
const ViewSubjects = lazy(() =>
  import("pages/SubjectManagement/Subject/ViewSubjects")
);

const EvaluationPricing = lazy(() =>
  import("pages/CourseManagement/EvaluationPricing/EvaluationPricing")
);

// ----------------------- Student Management -----------------------//
const ViewStudents = lazy(() =>
  import("pages/StudentManagement/Students/ViewStudents")
);
const ViewTabCourseAccess = lazy(() =>
  import("pages/StudentManagement/WebsiteAccessToTab/ViewTabCourseAccess")
);
const AddTabCourseAccess = lazy(() =>
  import("pages/StudentManagement/WebsiteAccessToTab/AddTabCourseAccess")
);
const ViewLiveLectureAccess = lazy(() =>
  import("pages/StudentManagement/LiveLectureAccess/ViewLiveLectureAccess")
);
const AddLiveLectureAccess = lazy(() =>
  import("pages/StudentManagement/LiveLectureAccess/AddLiveLectureAccess")
);
const ViewTabLectureAccess = lazy(() =>
  import(
    "pages/StudentManagement/TabLectureDownloadAccess/ViewTabLectureAccess"
  )
);
const AddTabLectureAccess = lazy(() =>
  import("pages/StudentManagement/TabLectureDownloadAccess/AddTabLectureAccess")
);
const SingleStudent = lazy(() =>
  import("pages/StudentManagement/Students/SingleStudent")
);
const CourseWiseStudent = lazy(() =>
  import("pages/StudentManagement/CourseWiseStudent/CourseWiseStudent")
);
const UpdateUserProfile = lazy(() =>
  import("pages/StudentManagement/UpdateStudentProfile/UpdateStudentProfile")
);
// ----------------------- Website Management -----------------------//
// const ViewCurrentAffairs = lazy(() =>
//   import("pages/WebsiteManagement/CurrentAffairs/ViewCurrentAffairs")
// );
// const AddCurrentAffairs = lazy(() =>
//   import("pages/WebsiteManagement/CurrentAffairs/AddCurrentAffairs")
// );
// const ViewBlogs = lazy(() => import("pages/WebsiteManagement/Blogs/ViewBlogs"));
// const AddBlog = lazy(() => import("pages/WebsiteManagement/Blogs/AddBlog"));
const ViewBanners = lazy(() =>
  import("pages/WebsiteManagement/Banner/ViewBanners")
);
const AddBanner = lazy(() =>
  import("pages/WebsiteManagement/Banner/AddBanner")
);
const UpdateBanner = lazy(() =>
  import("pages/WebsiteManagement/Banner/UpdateBanner")
);
const SingleBanner = lazy(() =>
  import("pages/WebsiteManagement/Banner/SingleBanner")
);
// const SingleCurrentAffairs = lazy(() =>
//   import("pages/WebsiteManagement/CurrentAffairs/SingleCurrentAffairs")
// );
// const UpdateCurrentAffairs = lazy(() =>
//   import("pages/WebsiteManagement/CurrentAffairs/UpdateCurrentAffairs")
// );
// const ViewBlogCategory = lazy(() =>
//   import("pages/WebsiteManagement/BlogCategory/ViewBlogCategory")
// );
const ViewStudentTestimonials = lazy(() =>
  import("pages/WebsiteManagement/StudentTestimonials/ViewStudentTestimonials")
);
const AddStudentTestimonial = lazy(() =>
  import("pages/WebsiteManagement/StudentTestimonials/AddStudentTestimonial")
);
const UpdateStudentTestimonial = lazy(() =>
  import("pages/WebsiteManagement/StudentTestimonials/UpdateStudentTestimonial")
);
const SingleStudentTestimonial = lazy(() =>
  import("pages/WebsiteManagement/StudentTestimonials/SingleStudentTestimonial")
);
// const SingleBlog = lazy(() =>
//   import("pages/WebsiteManagement/Blogs/SingleBlog")
// );
// const UpdateBlog = lazy(() =>
//   import("pages/WebsiteManagement/Blogs/UpdateBlog")
// );
const ViewOurPrograms = lazy(() =>
  import("pages/WebsiteManagement/OurPrograms/ViewOurPrograms")
);
const UpdateOurProgram = lazy(() =>
  import("pages/WebsiteManagement/OurPrograms/UpdateOurProgram")
);
const RegionalPrograms = lazy(() =>
  import("pages/WebsiteManagement/OurPrograms/RegionalPrograms")
);
const AddProgram = lazy(() =>
  import("pages/WebsiteManagement/OurPrograms/Regional/AddProgram")
);
const UpdateProgram = lazy(() =>
  import("pages/WebsiteManagement/OurPrograms/Regional/UpdateProgram")
);
const ViewToppers = lazy(() =>
  import("pages/WebsiteManagement/Toppers/ViewToppers")
);
const AddTopper = lazy(() =>
  import("pages/WebsiteManagement/Toppers/AddTopper")
);
const UpdateTopper = lazy(() =>
  import("pages/WebsiteManagement/Toppers/UpdateTopper")
);
const SingleTopper = lazy(() =>
  import("pages/WebsiteManagement/Toppers/SingleTopper")
);
const ViewWhyJoin = lazy(() =>
  import("pages/WebsiteManagement/WhyJoin/ViewWhyJoin")
);
const ViewTeacherDisplayOrder = lazy(() =>                                             
  import("pages/WebsiteManagement/TeacherDisplayOrder/ViewTeacherDisplayOrder")
);
const AddWhyJoin = lazy(() =>
  import("pages/WebsiteManagement/WhyJoin/AddWhyJoin")
);
const UpdateWhyJoin = lazy(() =>
  import("pages/WebsiteManagement/WhyJoin/UpdateWhyJoin")
);
const SingleWhyJoin = lazy(() =>
  import("pages/WebsiteManagement/WhyJoin/SingleWhyJoin")
);
const ViewSubSubjects = lazy(() =>
  import("pages/SubjectManagement/SubSubject/ViewSubSubject")
);

// ----------------------- Lecture Management -----------------------//
const ViewLectures = lazy(() => import("pages/LectureManagement/ViewLecture"));
const AddLecture = lazy(() => import("pages/LectureManagement/AddLecture"));
const UpdateLecture = lazy(() =>
  import("pages/LectureManagement/UpdateLecture")
);

// ----------------------- Staff Management -----------------------//
const AddStaff = lazy(() => import("pages/StaffManagement/AddStaff"));
const SingleStaff = lazy(() => import("pages/StaffManagement/SingleStaff"));
const UpdateStaff = lazy(() => import("pages/StaffManagement/UpdateStaff"));
const ViewStaffs = lazy(() => import("pages/StaffManagement/ViewStaffs"));

const IntroVideoMappingForStaff = lazy(() =>
  import("pages/StaffManagement/Mapping/IntroVideoMapping")
);
const RecommendedCoursesMappingForStaff = lazy(() =>
  import("pages/StaffManagement/Mapping/RecommendedCoursesMapping")
);
const DemoLectureMappingForStaff = lazy(() =>
  import("pages/StaffManagement/Mapping/DemoLectureMapping")
);

// ----------------------- Test Management -----------------------//
const AllTestListing = lazy(() =>
  import("pages/TestManagement/AllTest/AllTestListing")
);
const AddTest = lazy(() => import("pages/TestManagement/AllTest/AddTest"));
const UpdateTest = lazy(() =>
  import("pages/TestManagement/AllTest/UpdateTest")
);
const QuestionList = lazy(() =>
  import("pages/TestManagement/AllTest/QuestionSheet/QuestionList")
);

const AddQuestion = lazy(() =>
  import("pages/TestManagement/AllTest/QuestionSheet/AddQuestion")
);
const UpdateQuestion = lazy(() =>
  import("pages/TestManagement/AllTest/QuestionSheet/UpdateQuestion")
);
const TestBankListing = lazy(() =>
  import("pages/TestManagement/ArtifactBank/TestBankListing")
);
const MapTestToCourses = lazy(() =>
  import("pages/TestManagement/MapTestToCourses/MapTestToCourses")
);
const ArtifactMappingList = lazy(() =>
  import("pages/TestManagement/ArtifactMapping/ArtifactMappingList")
);

// ----------------------- Coupon Management -----------------------//
const ViewCoupons = lazy(() => import("pages/CouponManagement/ViewCoupons"));
const AddCoupon = lazy(() => import("pages/CouponManagement/AddCoupon"));
const UpdateCoupon = lazy(() => import("pages/CouponManagement/UpdateCoupon"));

// ----------------------- Master Bank -----------------------//
const ViewWebsiteVideoBank = lazy(() =>
  import("pages/MasterBank/WebsiteVideoBank/ViewVideoBank")
);
const AddWebsiteVideoBank = lazy(() =>
  import("pages/MasterBank/WebsiteVideoBank/AddVideoBank")
);
const UpdateWebsiteVideoBank = lazy(() =>
  import("pages/MasterBank/WebsiteVideoBank/UpdateVideoBank")
);

const ViewFaqs = lazy(() => import("pages/MasterBank/Faqs/ViewFaqs"));
const AddFaqs = lazy(() => import("pages/MasterBank/Faqs/AddFaqs"));
const UpdateFaqs = lazy(() => import("pages/MasterBank/Faqs/UpdateFaq"));

const ViewCoreTakeaway = lazy(() =>
  import("pages/MasterBank/CoreTakeAway/ViewCoreTakeaway")
);
const AddCoreTakeaway = lazy(() =>
  import("pages/MasterBank/CoreTakeAway/AddCoreTakeaway")
);
const UpdateCoreTakeaway = lazy(() =>
  import("pages/MasterBank/CoreTakeAway/UpdateCoreTakeaway")
);

// ----------------------- Help & Support -----------------------//
const ViewHelps = lazy(() => import("pages/Support/Help/ViewHelp"));
const ViewContact = lazy(() => import("pages/Support/Contact/ViewContact"));

// ----------------------- Live Lectures -----------------------//
const ViewLiveLectures = lazy(() =>
  import("pages/LiveLectures/ViewLiveLectures")
);
const AddLiveLecture = lazy(() => import("pages/LiveLectures/AddLiveLecture"));
const UpdateLiveLecture = lazy(() =>
  import("pages/LiveLectures/UpdateLiveLecture")
);

// ----------------------- Group Meetings -----------------------//
const ViewGroupMeetings = lazy(() =>
  import("pages/GroupMeetings/ViewGroupMeetings")
);
const AddGroupMeeting = lazy(() =>
  import("pages/GroupMeetings/AddGroupMeeting")
);
const UpdateGroupMeeting = lazy(() =>
  import("pages/GroupMeetings/UpdateGroupMeeting")
);

// ----------------------- Center Management -----------------------//
const ViewCenters = lazy(() => import("pages/CenterManagement/ViewCenters"));
const IntroVideoMapping = lazy(() =>
  import("pages/CenterManagement/mapping/IntroVideoMapping")
);
const BillingCoursesMapping = lazy(() =>
  import("pages/CenterManagement/mapping/BillingCoursesMapping")
);
const FaqMappingForCenter = lazy(() =>
  import("pages/CenterManagement/mapping/FaqMapping")
);

// ----------------------- Upcoming Courses -----------------------//
const UpcomingCourses = lazy(() =>
  import("pages/UpcomingCourses/UpcomingCourses")
);

// ----------------------- Daily Quiz -----------------------//
const ViewQuizs = lazy(() => import("pages/DailyQuiz/ViewQuizs"));
const AddQuiz = lazy(() => import("pages/DailyQuiz/AddQuiz"));
const UpdateQuiz = lazy(() => import("pages/DailyQuiz/UpdateQuiz"));

// ----------------------- Notification -----------------------//
const ViewNotifications = lazy(() =>
  import("pages/NotificationManagement/ViewNotifications")
);
const AddNotification = lazy(() =>
  import("pages/NotificationManagement/AddNotification")
);

// ----------------------- Evaluation Summary -----------------------//
const EvaluationSummary = lazy(() =>
  import("pages/Evaluation/EvaluationSummary/EvaluationSummary")
);
const ModeratorBestCopies = lazy(() =>
  import("pages/Evaluation/ModeratorBestCopies/ModeratorBestCopies")
);
const BestCopies = lazy(() => import("pages/Evaluation/BestCopies/BestCopies"));
const UploadAnswerSheet = lazy(() =>
  import("pages/Evaluation/UploadAnswerSheet/UploadAnswerSheet")
);
const EvaluatorDashboard = lazy(() =>
  import("pages/Evaluation/EvaluatorDashboard/EvaluatorDashboard")
);
const EvaluatorBilling = lazy(() =>
  import("pages/Evaluation/EvaluatorBilling/EvaluatorBilling")
);

// ----------------------- Permissions -----------------------//
const Permissions = lazy(() => import("pages/Permissions/permission"));

// ----------------------- Welcome Popup -----------------------//
const WelcomePopup = lazy(() =>
  import("pages/WebsiteManagement/WelcomePopup/WelcomePopup")
);

// ----------------------- Report management -----------------------//
const LoginReport = lazy(() => import("pages/Reports/LoginReport/LoginReport"));
const LectureReport = lazy(() =>
  import("pages/Reports/LectureReport/LectureReport")
);
const TestReport = lazy(() => import("pages/Reports/TestReport/TestReport"));
const ExceptionalLectureReport = lazy(() =>
  import("pages/Reports/ExceptionalLectureReport/ExceptionalLectureReport")
);
const MainsTestReport = lazy(() =>
  import("pages/Reports/MainsTestReport/MainsTestReport")
);
const GroupMeetingReport = lazy(() =>
  import("pages/Reports/GroupMeetingReport/GroupMeetingReport")
);
// const LectureDetailsHistory = lazy(() =>
//   import("pages/Reports/LectureDetailsHistory/LectureDetailsHistory")
// );
const LectureDetailsHistory = lazy(() =>
  import("pages/Reports/LectureDetails/LectureDetailsHistory")
);

export const sidebar = (roles = []) => {
  const sidebarData = [
    {
      id: 100000,
      enabled: roles.includes(100000),
      name: "Dashboard",
      icon: <DashboardIcon fontSize="small" />,
      path: "/dashboard",
      isChild: null,
    },
    {
      id: 110000,
      enabled: roles.includes(110000),
      name: "Manage Staff",
      icon: <Groups3Icon fontSize="small" />,
      path: "/staff-management",
      isChild: null,
    },
    {
      id: 120000,
      enabled: roles.includes(120000),
      name: "Course Management",
      icon: <MenuBookIcon fontSize="small" />,
      path: "/course-management",
      isChild: [
        {
          id: 120100,
          enabled: roles.includes(120100),
          name: "Billing Courses",
          path: "/course-management/billing-courses",
        },
        {
          id: 120100,
          enabled: roles.includes(120100),
          name: "IOS Billing Courses",
          path: "/course-management/ios-billing-courses",
        },
        {
          id: 120200,
          enabled: roles.includes(120200),
          name: "All Courses",
          path: "/course-management/courses",
        },
        {
          id: 120300,
          enabled: roles.includes(120300),
          name: "Course Category",
          path: "/course-management/course-category",
        },
        {
          id: 120400,
          enabled: roles.includes(120400),
          name: "Course Sub-Category",
          path: "/course-management/course-sub-category",
        },

        {
          id: 120400,
          // enabled: false,
          enabled: roles.includes(120400),
          name: "Sub-Category Platform",
          path: "/course-management/sub-category-platform",
        },

        {
          id: 120500,
          enabled: roles.includes(120500),
          name: "GPS Enable to courses",
          path: "/course-management/gps-enable-course",
        },
        {
          id: 120600,
          enabled: roles.includes(120600),
          name: "GPS Access to Student",
          path: "/course-management/gps-access-student",
        },
        {
          id: 120700,
          enabled: roles.includes(120700),
          name: "Map Subject To Course",
          path: "/course-management/map-course",
        },
        {
          id: 120800,
          enabled: roles.includes(120800),
          name: "assign courses to affiliates",
          path: "/course-management/course-mapping",
        },
        // {
        //   id: 120800,
        //   // enabled: roles.includes(120800),
        //   enabled: false,
        //   name: "Evaluation Pricing",
        //   path: "/course-management/evaluation-pricing",
        // },
        {
          id: 120800,
          // enabled: roles.includes(120800),
          enabled: false,
          name: "Evaluation Pricing",
          path: "/course-management/evaluation-pricing",
        },
      ],
    },
    {
      id: 130000,
      enabled: roles.includes(130000),
      name: "Student Management",
      icon: <SchoolIcon fontSize="small" />,
      path: "/student-management",
      isChild: [
        {
          id: 130100,
          enabled: roles.includes(130100),
          name: "All Student List",
          path: "/student-management/all-students",
        },
        {
          id: 130200,
          enabled: roles.includes(130200),
          name: "Course Wise Student",
          path: "/student-management/course-wise-student",
        },
        {
          id: 130300,
          // enabled: roles.includes(130300),
          enabled: false,
          name: "Website Access to tab Course",
          path: "/student-management/tab-course-access",
        },
        {
          id: 130400,
          // enabled: roles.includes(130400),
          enabled: false,
          name: "Live Lecture Access on website",
          path: "/student-management/live-lecture-access",
        },
        {
          id: 130500,
          enabled: roles.includes(130500),
          name: "TAB-Lecture Download Access",
          path: "/student-management/tab-lecture-download-access",
        },
        {
          id: 130600,
          enabled: roles.includes(130600),
          name: "Update student profile",
          path: "/student-management/update-profile",
        },
      ],
    },
    {
      id: 140000,
      enabled: roles.includes(140000),
      name: "Test Bank Management",
      icon: <HistoryEduIcon fontSize="small" />,
      path: "/test-management",
      isChild: [
        {
          id: 140100,
          enabled: roles.includes(140100),
          name: "All Test",
          path: "/test-management/test",
        },
        {
          id: 140200,
          enabled: roles.includes(140200),
          name: "Artifacts Bank",
          path: "/test-management/test-bank",
        },
        {
          id: 140300,
          enabled: roles.includes(140300),
          name: "Artifacts Mapping",
          path: "/test-management/artifacts-mapping",
        },
        {
          id: 140400,
          enabled: roles.includes(140400),
          name: "Map Tests to Courses",
          path: "/test-management/map-test-to-courses",
        },
      ],
    },
    {
      id: 310000,
      enabled: roles.includes(310000),
      name: "Evaluation",
      icon: <PlaylistAddCheckCircleIcon fontSize="small" />,
      path: "/evaluation-management",
      isChild: [
        {
          id: 310100,
          enabled: roles.includes(310100),
          name: "Evaluation Summary",
          path: "/evaluation-management/evaluation-summary",
        },
        {
          id: 310200,
          enabled: roles.includes(310200),
          name: "Moderator Best Copies",
          path: "/evaluation-management/moderator-best-copies",
        },
        {
          id: 310300,
          enabled: roles.includes(310300),
          name: "Best Copies",
          path: "/evaluation-management/best-copies",
        },
        {
          id: 310400,
          enabled: roles.includes(310400),
          name: "Upload Answer Sheet",
          path: "/evaluation-management/upload-answer-sheet",
        },
        {
          id: 310500,
          enabled: roles.includes(310500),
          name: "Evaluator Dashboard",
          path: "/evaluation-management/evaluator-dashboard",
        },
        {
          id: 310600,
          enabled: roles.includes(310600),
          name: "Evaluator Billing",
          path: "/evaluation-management/evaluator-billing",
        },
      ],
    },
    {
      id: 150000,
      enabled: roles.includes(150000),
      name: "Website Management",
      icon: <LanguageIcon fontSize="small" />,
      path: "/website-management",
      isChild: [
        {
          id: 150100,
          enabled: roles.includes(150100),
          name: "Banner",
          path: "/website-management/banners",
        },
        {
          id: 150300,
          enabled: roles.includes(150300),
          name: "Student Testimonials",
          path: "/website-management/student-testimonials",
        },
        {
          id: 150700,
          enabled: roles.includes(150700),
          name: "Our Programs",
          path: "/website-management/our-programs",
        },
        {
          id: 150800,
          enabled: roles.includes(150800),
          name: "Institute Toppers",
          path: "/website-management/institute-toppers",
        },
        {
          id: 150900,
          enabled: roles.includes(150900),
          name: "Why Join Us",
          path: "/website-management/why-join-us",
        },
        {
          id: 130300,                                                         
          enabled: roles.includes(130300),
          name: "teacher display order",
          path: "/website-management/teacher-display-order",
        },
        {
          id: 151000,
          enabled: roles.includes(151000),
          name: "Advertisement",
          path: "/website-management/welcome-popup",
        },
        {
          id: 161000,
          enabled: roles.includes(161000),
          name: "Site Setting",
          path: "/website-management/site-setting",
        },
      ],
    },
    {
      id: 160000,
      enabled: roles.includes(160000),
      name: "Master Bank",
      icon: <AccountBalanceIcon fontSize="small" />,
      path: "/bank-management",
      isChild: [
        {
          id: 160100,
          enabled: roles.includes(160100),
          name: "Faqs",
          path: "/bank-management/faqs",
        },
        {
          id: 160200,
          enabled: roles.includes(160200),
          name: "Core Takeaway",
          path: "/bank-management/core-takeaway",
        },
        {
          id: 160300,
          enabled: roles.includes(160300),
          name: "Website Video Bank",
          path: "/bank-management/website-video-bank",
        },
      ],
    },
    {
      id: 170000,
      enabled: roles.includes(170000),
      name: "Centre Management",
      icon: <HubIcon fontSize="small" />,
      path: "/center-management",
      isChild: null,
    },
    {
      id: 180000,
      enabled: roles.includes(180000),
      name: "Upcoming Courses",
      icon: <UpcomingIcon fontSize="small" />,
      path: "/upcoming-courses",
      isChild: null,
    },
    {
      id: 190000,
      enabled: roles.includes(190000),
      name: "Daily Quiz",
      icon: <QuizIcon fontSize="small" />,
      path: "/daily-quiz",
      isChild: null,
    },
    {
      id: 200000,
      enabled: roles.includes(200000),
      name: "Lectures Management",
      icon: <FilterFramesIcon fontSize="small" />,
      path: "/lectures-management",
      isChild: null,
    },
    {
      id: 210000,
      enabled: roles.includes(210000),
      name: "Live Lectures",
      icon: <CastForEducationIcon fontSize="small" />,
      path: "/live-lectures",
      isChild: null,
    },
    {
      id: 220000,
      enabled: roles.includes(220000),
      name: "Group Meetings",
      icon: <Groups3Icon fontSize="small" />,
      path: "/group-meetings",
      isChild: null,
    },
    {
      id: 230000,
      enabled: roles.includes(230000),
      name: "Coupon Management",
      icon: <DiscountIcon fontSize="small" />,
      path: "/coupons-management",
      isChild: null,
    },
    {
      id: 240000,
      enabled: roles.includes(240000),
      name: "Subject Management",
      icon: <SubjectIcon fontSize="small" />,
      path: "/subject-management",
      isChild: [
        {
          id: 240100,
          enabled: roles.includes(240100),
          name: "Subject",
          path: "/subject-management/subject",
        },
        {
          id: 240200,
          enabled: roles.includes(240200),
          name: "Sub Subject",
          path: "/subject-management/sub-subject",
        },
      ],
    },
    {
      id: 250000,
      enabled: roles.includes(250000),
      name: "Support",
      icon: <HelpIcon fontSize="small" />,
      path: "/support-management",
      isChild: [
        {
          id: 250100,
          enabled: roles.includes(250100),
          name: "Helps",
          path: "/support-management/helps",
        },
        {
          id: 250200,
          enabled: roles.includes(250200),
          name: "Contact us",
          path: "/support-management/contact",
        },
      ],
    },
    {
      id: 260000,
      enabled: roles.includes(260000),
      name: "Notifications",
      icon: <NotificationsNoneIcon fontSize="small" />,
      path: "/notifications",
      isChild: null,
    },
    {
      id: 320000,
      // enabled: roles.includes(320000),
      enabled: false,
      name: "Permissions",
      icon: <SettingsAccessibilityIcon fontSize="small" />,
      path: "/permissions",
      isChild: null,
    },
    {
      id: 330000,
      enabled: roles.includes(330000),
      name: "Reports",
      icon: <AssessmentIcon fontSize="small" />,
      path: "/reports-management",
      isChild: [
        {
          id: 330100,
          enabled: roles.includes(330100),
          name: "Login Report",
          path: "/reports-management/login",
        },
        {
          id: 330200,
          enabled: roles.includes(330200),
          name: "Lecture Report",
          path: "/reports-management/lecture",
        },
        {
          id: 330300,
          enabled: roles.includes(330300),
          name: "Prelims Test Report",
          path: "/reports-management/prelims-test",
        },
        {
          id: 330400,
          enabled: roles.includes(330400),
          name: "Mains Test Report",
          path: "/reports-management/mains-test",
        },
        {
          id: 330500,
          enabled: roles.includes(330500),
          name: "Exceptional Lecture Report",
          path: "/reports-management/exceptional-lecture",
        },
        {
          id: 330600,
          enabled: roles.includes(330600),
          name: "Group Meeting Report",
          path: "/reports-management/group-meeting",
        },
        {
          id: 330700,
          enabled: roles.includes(330700),
          name: "Lecture View Location Report",
          path: "/reports-management/lecture-details-history",
        },
        {
          id: 330800,
          enabled: roles.includes(330800),
          name: "LiveLecture View Location Report",
          path: "/reports-management/live-lecture-details-history",
        },
      ],
    },
    {
      id: 270000,
      enabled: false,
      name: "Course Admin",
      icon: <AdminPanelSettingsIcon fontSize="small" />,
      path: "/course-admin",
      isChild: null,
    },
    {
      id: 280000,
      enabled: false,
      name: "Mentors",
      icon: <SupervisedUserCircleIcon fontSize="small" />,
      path: "/mentors",
      isChild: null,
    },
    {
      id: 290000,
      enabled: false,
      name: "Counsellor",
      icon: <SupervisedUserCircleIcon fontSize="small" />,
      path: "/counsellor",
      isChild: null,
    },
    {
      id: 300000,
      enabled: false,
      name: "Topper",
      icon: <WorkspacePremiumIcon fontSize="small" />,
      path: "/topper",
      isChild: null,
    },
  ].filter((side) => side.enabled);

  return sidebarData;
};

export const routes = (roles = []) => {
  const routesData = [
    {
      id: 100000,
      enabled: roles.includes(100000),
      element: <Dashboard />,
      path: "/dashboard",
    },
    {
      id: 110001,
      enabled: roles.includes(110001),
      element: <AddStaff />,
      path: "/staff-management/add",
    },
    {
      id: 110002,
      enabled: roles.includes(110002),
      element: <UpdateStaff />,
      path: "/staff-management/update/:id",
    },
    {
      id: 110003,
      enabled: roles.includes(110003),
      element: <SingleStaff />,
      path: "/staff-management/view/:id",
    },
    {
      id: 110000,
      enabled: roles.includes(110000),
      element: <ViewStaffs />,
      path: "/staff-management",
    },
    {
      id: 110006,
      enabled: roles.includes(110006),
      element: <IntroVideoMappingForStaff />,
      path: "/staff-management/:type/link-intro-video/:staffId",
    },
    {
      id: 110007,
      enabled: roles.includes(110007),
      element: <RecommendedCoursesMappingForStaff />,
      path: "/staff-management/:type/recommended-course-mapping/:staffId",
    },
    {
      id: 110008,
      enabled: roles.includes(110008),
      element: <DemoLectureMappingForStaff />,
      path: "/staff-management/:type/demo-lecture-mapping/:staffId",
    },
    {
      id: 120101,
      enabled: roles.includes(120101),
      element: <AddBillingCourse />,
      path: "/course-management/billing-courses/add",
    },
    {
      id: 120102,
      enabled: roles.includes(120102),
      element: <UpdateBillingCourse />,
      path: "/course-management/billing-courses/update/:id",
    },
    {
      id: 120103,
      enabled: roles.includes(120103),
      element: <SingleBillingCourse />,
      path: "/course-management/billing-courses/view/:id",
    },
    {
      id: 120100,
      enabled: roles.includes(120100),
      element: <ViewBillingCourses />,
      path: "/course-management/billing-courses",
    },
    {
      id: 120100,
      enabled: roles.includes(120100),
      element: <IOSBillingCourse />,
      path: "/course-management/ios-billing-courses",
    },
    {
      id: 120106,
      enabled: roles.includes(120106),
      element: <DetailVideoMapping />,
      path: "/course-management/billing-courses/link-course-video/:billingId",
    },
    {
      id: 120107,
      enabled: roles.includes(120107),
      element: <RecommendedCoursesMapping />,
      path: "/course-management/billing-courses/recommended-course-mapping/:billingId",
    },
    {
      id: 120108,
      enabled: roles.includes(120108),
      element: <DemoLectureMapping />,
      path: "/course-management/billing-courses/demo-lecture-mapping/:billingId",
    },
    {
      id: 120109,
      enabled: roles.includes(120109),
      element: <FaqMapping />,
      path: "/course-management/billing-courses/faq-mapping/:billingId",
    },
    {
      id: 120110,
      enabled: roles.includes(120110),
      element: <CoreTakeAwayMapping />,
      path: "/course-management/billing-courses/core-takeaway-mapping/:billingId",
    },
    {
      id: 120111,
      enabled: roles.includes(120111),
      element: <CoursePlatformMapping />,
      path: "/course-management/billing-courses/course-platform-detail-mapping/:billingId",
    },
    {
      id: 120700,
      enabled: roles.includes(120700),
      element: <ViewMappedSubject />,
      path: "/course-management/map-course",
    },
    {
      id: 120800,
      enabled: roles.includes(120800),
      element: <CourseMapping/>,
      path: "/course-management/course-mapping",
    },
    
    // {
    //   id: 120800,
    //   // enabled: roles.includes(120800),
    //   enabled: false,
    //   element: <EvaluationPricing />,
    //   path: "/course-management/evaluation-pricing",
    // },
    {
      id: 120800,
      // enabled: roles.includes(120800),
      enabled: false,
      element: <EvaluationPricing />,
      path: "/course-management/evaluation-pricing",
    },
    {
      id: 120301,
      enabled: roles.includes(120301),
      element: <AddCourseCategory />,
      path: "/course-management/course-category/add",
    },
    {
      id: 120302,
      enabled: roles.includes(120302),
      element: <UpdateCourseCategory />,
      path: "/course-management/course-category/update/:id",
    },
    {
      id: 120303,
      enabled: roles.includes(120303),
      element: <SingleCourseCategory />,
      path: "/course-management/course-category/view/:id",
    },
    {
      id: 120300,
      enabled: roles.includes(120300),
      element: <ViewCourseCategory />,
      path: "/course-management/course-category",
    },
    {
      id: 120400,
      enabled: roles.includes(120400),
      element: <ViewSubCategories />,
      path: "/course-management/course-sub-category",
    },

    {
      id: 120400,
      // enabled: false,
      enabled: roles.includes(120400),
      element: <ViewSubCategoryPlatform />,
      path: "/course-management/sub-category-platform",
    },

    {
      id: 120501,
      enabled: roles.includes(120501),
      element: <AddGPSCourse />,
      path: "/course-management/gps-enable-course/add",
    },
    {
      id: 120502,
      enabled: roles.includes(120502),
      element: <UpdateGPSCourse />,
      path: "/course-management/gps-enable-course/update/:id",
    },
    {
      id: 120503,
      enabled: roles.includes(120503),
      element: <SingleGPSCourse />,
      path: "/course-management/gps-enable-course/view/:id",
    },
    {
      id: 120500,
      enabled: roles.includes(120500),
      element: <ViewGPSCourses />,
      path: "/course-management/gps-enable-course",
    },

    {
      id: 120600,
      enabled: roles.includes(120600),
      element: <ViewGPSAccessStudent />,
      path: "/course-management/gps-access-student",
    },
    {
      id: 120201,
      enabled: roles.includes(120201),
      element: <AddCourse />,
      path: "/course-management/courses/add",
    },
    {
      id: 120202,
      enabled: roles.includes(120202),
      element: <UpdateCourse />,
      path: "/course-management/courses/update/:id",
    },
    {
      id: 120200,
      enabled: roles.includes(120200),
      element: <ViewCourses />,
      path: "/course-management/courses",
    },
    {
      id: 120206,
      enabled: roles.includes(120206),
      element: <CourseSectionList />,
      path: "/course-management/courses/:courseId/sections",
    },
    {
      id: 120207,
      enabled: roles.includes(120207),
      element: <CourseLectureList />,
      path: "/course-management/courses/:courseId/lectures",
    },
    {
      id: 120208,
      enabled: roles.includes(120208),
      element: <SectionLectureList />,
      path: "/course-management/courses/:courseId/sections/:sectionId/lectures",
    },
    {
      id: 150101,
      enabled: roles.includes(150101),
      element: <AddBanner />,
      path: "/website-management/banners/add",
    },
    {
      id: 150102,
      enabled: roles.includes(150102),
      element: <UpdateBanner />,
      path: "/website-management/banners/update/:id",
    },
    {
      id: 150103,
      enabled: roles.includes(150103),
      element: <SingleBanner />,
      path: "/website-management/banners/view/:id",
    },
    {
      id: 150100,
      enabled: roles.includes(150100),
      element: <ViewBanners />,
      path: "/website-management/banners",
    },
    {
      id: 150700,
      enabled: roles.includes(150700),
      element: <ViewOurPrograms />,
      path: "/website-management/our-programs",
    },
    {
      id: 150702,
      enabled: roles.includes(150702),
      element: <UpdateOurProgram />,
      path: "/website-management/our-programs/:program",
    },
    {
      id: 150706,
      enabled: roles.includes(150706),
      element: <RegionalPrograms />,
      path: "/website-management/our-programs/regional-program",
    },
    {
      id: 150707,
      enabled: roles.includes(150707),
      element: <AddProgram />,
      path: "/website-management/our-programs/regional-program/add",
    },
    {
      id: 150708,
      enabled: roles.includes(150708),
      element: <UpdateProgram />,
      path: "/website-management/our-programs/regional-program/update/:center",
    },
    {
      id: 150300,
      enabled: roles.includes(150300),
      element: <ViewStudentTestimonials />,
      path: "/website-management/student-testimonials",
    },
    {
      id: 150301,
      enabled: roles.includes(150301),
      element: <AddStudentTestimonial />,
      path: "/website-management/student-testimonials/add",
    },
    {
      id: 150302,
      enabled: roles.includes(150302),
      element: <UpdateStudentTestimonial />,
      path: "/website-management/student-testimonials/update/:id",
    },
    {
      id: 150303,
      enabled: roles.includes(150303),
      element: <SingleStudentTestimonial />,
      path: "/website-management/student-testimonials/view/:id",
    },
    {
      id: 150801,
      enabled: roles.includes(150801),
      element: <AddTopper />,
      path: "/website-management/institute-toppers/add",
    },
    {
      id: 150802,
      enabled: roles.includes(150802),
      element: <UpdateTopper />,
      path: "/website-management/institute-toppers/update/:id",
    },
    {
      id: 150803,
      enabled: roles.includes(150803),
      element: <SingleTopper />,
      path: "/website-management/institute-toppers/view/:id",
    },
    {
      id: 150800,
      enabled: roles.includes(150800),
      element: <ViewToppers />,
      path: "/website-management/institute-toppers",
    },
    {
      id: 150901,
      enabled: roles.includes(150901),
      element: <AddWhyJoin />,
      path: "/website-management/why-join-us/add",
    },
    {
      id: 150902,
      enabled: roles.includes(150902),
      element: <UpdateWhyJoin />,
      path: "/website-management/why-join-us/update/:id",
    },
    {
      id: 150903,
      enabled: roles.includes(150903),
      element: <SingleWhyJoin />,
      path: "/website-management/why-join-us/view/:id",
    },
    {
      id: 150900,
      enabled: roles.includes(150900),
      element: <ViewWhyJoin />,
      path: "/website-management/why-join-us",
    },

    {
      id: 130300,
      enabled: roles.includes(130300),                                        
      element: <ViewTeacherDisplayOrder />,
      path: "/website-management/teacher-display-order",
    },

    {
      id: 151000,
      enabled: roles.includes(151000),
      element: <WelcomePopup />,
      path: "/website-management/welcome-popup",
    },

    {
      id: 161000,
      enabled: roles.includes(161000),
      element: <ViewSiteSetting />,
      path: "/website-management/site-setting",
    },


    {
      id: 240100,
      enabled: roles.includes(240100),
      element: <ViewSubjects />,
      path: "/subject-management/subject",
    },
    {
      id: 240200,
      enabled: roles.includes(240200),
      element: <ViewSubSubjects />,
      path: "/subject-management/sub-subject",
    },

    {
      id: 130103,
      enabled: roles.includes(130103),
      element: <SingleStudent />,
      path: "/student-management/all-students/view/:id",
    },
    {
      id: 130100,
      enabled: roles.includes(130100),
      element: <ViewStudents />,
      path: "/student-management/all-students",
    },
    {
      id: 130301,
      enabled: roles.includes(130301),
      element: <AddTabCourseAccess />,
      path: "/student-management/tab-course-access/add",
    },
    {
      id: 130300,
      enabled: roles.includes(130300),
      element: <ViewTabCourseAccess />,
      path: "/student-management/tab-course-access",
    },
    {
      id: 130401,
      enabled: roles.includes(130401),
      element: <AddLiveLectureAccess />,
      path: "/student-management/live-lecture-access/add",
    },
    {
      id: 130400,
      enabled: roles.includes(130400),
      element: <ViewLiveLectureAccess />,
      path: "/student-management/live-lecture-access",
    },
    {
      id: 130501,
      enabled: roles.includes(130501),
      element: <AddTabLectureAccess />,
      path: "/student-management/tab-lecture-download-access/add",
    },
    {
      id: 130500,
      enabled: roles.includes(130500),
      element: <ViewTabLectureAccess />,
      path: "/student-management/tab-lecture-download-access/",
    },
    {
      id: 130600,
      enabled: roles.includes(130600),
      element: <UpdateUserProfile />,
      path: "/student-management/update-profile",
    },
    {
      id: 130200,
      enabled: roles.includes(130200),
      element: <CourseWiseStudent />,
      path: "/student-management/course-wise-student/",
    },
    {
      id: 200001,
      enabled: roles.includes(200001),
      element: <AddLecture />,
      path: "/lectures-management/add",
    },
    {
      id: 200002,
      enabled: roles.includes(200002),
      element: <UpdateLecture />,
      path: "/lectures-management/update/:id",
    },
    {
      id: 200000,
      enabled: roles.includes(200000),
      element: <ViewLectures />,
      path: "/lectures-management",
    },
    {
      id: 210001,
      enabled: roles.includes(210001),
      element: <AddLiveLecture />,
      path: "/live-lectures/add",
    },
    {
      id: 210002,
      enabled: roles.includes(210002),
      element: <UpdateLiveLecture />,
      path: "/live-lectures/update/:id",
    },
    {
      id: 210000,
      enabled: roles.includes(210000),
      element: <ViewLiveLectures />,
      path: "/live-lectures",
    },
    {
      id: 220001,
      enabled: roles.includes(220001),
      element: <AddGroupMeeting />,
      path: "/group-meetings/add",
    },
    {
      id: 220002,
      enabled: roles.includes(220002),
      element: <UpdateGroupMeeting />,
      path: "/group-meetings/update/:id",
    },
    {
      id: 220000,
      enabled: roles.includes(220000),
      element: <ViewGroupMeetings />,
      path: "/group-meetings",
    },
    {
      id: 140100,
      enabled: roles.includes(140100),
      element: <AllTestListing />,
      path: "/test-management/test",
    },
    {
      id: 140101,
      enabled: roles.includes(140101),
      element: <AddTest />,
      path: "/test-management/test/add",
    },
    {
      id: 140102,
      enabled: roles.includes(140102),
      element: <UpdateTest />,
      path: "/test-management/test/update/:id",
    },
    {
      id: 140106,
      enabled: roles.includes(140106),
      element: <QuestionList />,
      path: "/test-management/test/:id",
    },
    {
      id: 140107,
      enabled: roles.includes(140107),
      element: <AddQuestion />,
      path: "/test-management/test/:id/add",
    },
    {
      id: 140108,
      enabled: roles.includes(140108),
      element: <UpdateQuestion />,
      path: "/test-management/test/:id/update/:questionId",
    },
    {
      id: 140200,
      enabled: roles.includes(140200),
      element: <TestBankListing />,
      path: "/test-management/test-bank",
    },
    {
      id: 140400,
      enabled: roles.includes(140400),
      element: <MapTestToCourses />,
      path: "/test-management/map-test-to-courses",
    },
    {
      id: 140300,
      enabled: roles.includes(140300),
      element: <ArtifactMappingList />,
      path: "/test-management/artifacts-mapping",
    },
    {
      id: 230000,
      enabled: roles.includes(230000),
      element: <ViewCoupons />,
      path: "/coupons-management",
    },
    {
      id: 230001,
      enabled: roles.includes(230001),
      element: <AddCoupon />,
      path: "/coupons-management/add",
    },
    {
      id: 230002,
      enabled: roles.includes(230002),
      element: <UpdateCoupon />,
      path: "/coupons-management/update/:id",
    },
    {
      id: 160100,
      enabled: roles.includes(160100),
      element: <ViewFaqs />,
      path: "/bank-management/faqs",
    },
    {
      id: 160101,
      enabled: roles.includes(160101),
      element: <AddFaqs />,
      path: "/bank-management/faqs/add",
    },
    {
      id: 160102,
      enabled: roles.includes(160102),
      element: <UpdateFaqs />,
      path: "/bank-management/faqs/update/:id",
    },
    {
      id: 160200,
      enabled: roles.includes(160200),
      element: <ViewCoreTakeaway />,
      path: "/bank-management/core-takeaway",
    },
    {
      id: 160201,
      enabled: roles.includes(160201),
      element: <AddCoreTakeaway />,
      path: "/bank-management/core-takeaway/add",
    },
    {
      id: 160202,
      enabled: roles.includes(160202),
      element: <UpdateCoreTakeaway />,
      path: "/bank-management/core-takeaway/update/:id",
    },
    // {
    //   enabled: false,
    //   element: <ViewMasterVideoBank />,
    //   path: "/bank-management/video-bank",
    // },
    // {
    //   enabled: false,
    //   element: <AddMasterVideoBank />,
    //   path: "/bank-management/video-bank/add",
    // },
    // {
    //   enabled: false,
    //   element: <UpdateMasterVideoBank />,
    //   path: "/bank-management/video-bank/update/:id",
    // },
    {
      id: 160300,
      enabled: roles.includes(160300),
      element: <ViewWebsiteVideoBank />,
      path: "/bank-management/website-video-bank",
    },
    {
      id: 160301,
      enabled: roles.includes(160301),
      element: <AddWebsiteVideoBank />,
      path: "/bank-management/website-video-bank/add",
    },
    {
      id: 160302,
      enabled: roles.includes(160302),
      element: <UpdateWebsiteVideoBank />,
      path: "/bank-management/website-video-bank/update/:id",
    },
    {
      id: 250200,
      enabled: roles.includes(250200),
      element: <ViewContact />,
      path: "/support-management/contact",
    },
    {
      id: 250100,
      enabled: roles.includes(250100),
      element: <ViewHelps />,
      path: "/support-management/helps",
    },
    {
      id: 170000,
      enabled: roles.includes(170000),
      element: <ViewCenters />,
      path: "/center-management",
    },
    {
      id: 170006,
      enabled: roles.includes(170006),
      element: <IntroVideoMapping />,
      path: "/center-management/link-intro-video/:centerId",
    },
    {
      id: 170007,
      enabled: roles.includes(170007),
      element: <BillingCoursesMapping />,
      path: "/center-management/billing-course-mapping/:centerId",
    },
    {
      id: 170008,
      enabled: roles.includes(170008),
      element: <FaqMappingForCenter />,
      path: "/center-management/faq-mapping/:centerId",
    },
    {
      id: 180000,
      enabled: roles.includes(180000),
      element: <UpcomingCourses />,
      path: "/upcoming-courses",
    },
    {
      id: 190000,
      enabled: roles.includes(190000),
      element: <ViewQuizs />,
      path: "/daily-quiz",
    },
    {
      id: 190001,
      enabled: roles.includes(190001),
      element: <AddQuiz />,
      path: "/daily-quiz/add",
    },
    {
      id: 190002,
      enabled: roles.includes(190002),
      element: <UpdateQuiz />,
      path: "/daily-quiz/update/:id",
    },
    {
      id: 260000,
      enabled: roles.includes(260000),
      element: <ViewNotifications />,
      path: "/notifications",
    },
    {
      id: 260001,
      enabled: roles.includes(260001),
      element: <AddNotification />,
      path: "/notifications/add",
    },
    {
      id: 310100,
      enabled: roles.includes(310100),
      element: <EvaluationSummary />,
      path: "/evaluation-management/evaluation-summary",
    },
    {
      id: 310200,
      enabled: roles.includes(310200),
      element: <ModeratorBestCopies />,
      path: "/evaluation-management/moderator-best-copies",
    },
    {
      id: 310300,
      enabled: roles.includes(310300),
      element: <BestCopies />,
      path: "/evaluation-management/best-copies",
    },
    {
      id: 310400,
      enabled: roles.includes(310400),
      element: <UploadAnswerSheet />,
      path: "/evaluation-management/upload-answer-sheet",
    },
    {
      id: 310500,
      enabled: roles.includes(310500),
      element: <EvaluatorDashboard />,
      path: "/evaluation-management/evaluator-dashboard",
    },
    {
      id: 310600,
      enabled: roles.includes(310600),
      element: <EvaluatorBilling />,
      path: "/evaluation-management/evaluator-billing",
    },
    {
      id: 320000,
      enabled: roles.includes(320000),
      element: <Permissions />,
      path: "/permissions",
    },
    {
      id: 330100,
      enabled: roles.includes(330100),
      element: <LoginReport />,
      path: "/reports-management/login",
    },
    {
      id: 330200,
      enabled: roles.includes(330200),
      element: <LectureReport />,
      path: "/reports-management/lecture",
    },
    {
      id: 330300,
      enabled: roles.includes(330300),
      element: <TestReport />,
      path: "/reports-management/prelims-test",
    },
    {
      id: 330400,
      enabled: roles.includes(330400),
      element: <MainsTestReport />,
      path: "/reports-management/mains-test",
    },
    {
      id: 330500,
      enabled: roles.includes(330500),
      element: <ExceptionalLectureReport />,
      path: "/reports-management/exceptional-lecture",
    },
    {
      id: 330600,
      enabled: roles.includes(330600),
      element: <GroupMeetingReport />,
      path: "/reports-management/group-meeting",
    },
    
    {
      id: 330700,
      enabled: roles.includes(330700),
      element: <LectureDetailsHistory/>,
      path: "/reports-management/lecture-details-history",
    },
    {
      id: 330800,
      enabled: roles.includes(330800),
      element: <LiveLectureDetailsHistory/>,
      path: "/reports-management/live-lecture-details-history",
    },
    {
      enabled: true,
      element: <ChangePassword />,
      path: "/change-password",
    },
  ].filter((route) => route.enabled);
  return routesData;
};
