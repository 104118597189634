import { decryptData, encryptData } from "config/config";
import { instance as axios, setMultiPartHeader } from "config/axiosInstance";
import { isLoggedIn } from "utils";

export const getLectures = async (data) => {
  return decryptData(
    await axios.get(
      `/lecture?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const addLecture = async (data) => {
  return decryptData(
    await axios.post(`/lecture-create`, data, setMultiPartHeader())
  );
};

export const updateLecture = async (data) => {
  return decryptData(
    await axios.post(`/lecture-edit`, data, setMultiPartHeader())
  );
};

export const updateLectureStatus = async (data) => {
  return decryptData(
    await axios.get(
      `/lecture-status?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const deleteLecture = async (data) => {
  return decryptData(
    await axios.get(
      `/lecture-delete?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getSingleLectureById = async (data) => {
  return decryptData(
    await axios.get(
      `/lecture-view?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const lectureDetailsHistory = async (data) => {
  return decryptData(
    await axios.get(
      `/play-lecture-detail-history?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const livelectureDetailsHistory = async (data) => {
  return decryptData(
    await axios.get(
      `/play-live-lecture-detail-history?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// export const evaluatorBillingExcelDownload = async (data) => {
//   return `${
//     process.env.REACT_APP_API_URL
//   }/admin/evaluation/evaluator/billing?reqData=${encryptData({
//     ...data,
//     access_token: isLoggedIn(),
//   })}`;
// };

// __________ vdo cipher Lecture video otp generate __________
export const vdoCipherGenerateOtp = async (data = {}) => {
  const token = isLoggedIn();
  return decryptData(
    await axios.post(`/play-lecture-otp`, {
      reqData: encryptData({
        ...data,
        access_token: token,
      }),
    })
  );
};
